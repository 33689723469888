import request from '@/utils/request'

const urban_land_use_taxApi = {
  urban_land_use_tax_list: '/urban_land_use_tax/',
  urban_land_use_tax_create: '/urban_land_use_tax/',
  urban_land_use_tax_update: '/urban_land_use_tax/',
  urban_land_use_tax_delete: '/urban_land_use_tax/'
}

/**
 * 列表
 */
export function urban_land_use_tax_list (parameter) {
  return request({
    url: urban_land_use_taxApi.urban_land_use_tax_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function urban_land_use_tax_create (parameter) {
  return request({
    url: urban_land_use_taxApi.urban_land_use_tax_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function urban_land_use_tax_update (parameter, urban_land_use_tax_id) {
  return request({
    url: urban_land_use_taxApi.urban_land_use_tax_update + urban_land_use_tax_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function urban_land_use_tax_delete (urban_land_use_tax_id) {
  return request({
    url: urban_land_use_taxApi.urban_land_use_tax_delete + urban_land_use_tax_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
